import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

// JSON-LD

const SDBreadcrumb = ( {url} ) => {

    // feature: breadcrumb
    // https://schema.org/breadcrumb 
    // https://developers.google.com/search/docs/data-types/breadcrumb

    const { site } = useStaticQuery(
        graphql`
        query {
            site: file(relativePath: {eq: "settings/settings-sitemeta.md"}) {
                id
                childMarkdownRemark {
                  frontmatter {
                    defaultUrl: siteurl
                    defaultName: titlecompany
                  }
                }
              }
        }
      `)

      const {
        defaultUrl,
        defaultName,
    } = site.childMarkdownRemark.frontmatter

    const crumbs = url.split('/')

    let itemListElement = []
    let precrumb = ""
    const host = defaultUrl
    crumbs.map((crumb, index) => (
        (crumb !== "" &&
        itemListElement.push(
            {
                "@type": "ListItem",
                "position": index,
                "item": host+precrumb+"/"+crumb,
                "name": crumb = (crumb.replace(/\b([a-zA-Z]|\d+)/g, function($1) { return $1.toUpperCase() }).replace(/-/g, ' ')),
            }
        ),
        crumb = (crumb.replace(/\b([a-zA-Z]|\d+)/g, function($1) { return $1.toLowerCase() }).replace(/ /g, '-')),
        index!==0 && (precrumb = "/"+crumb))
    ))
    let breadCrumbs= [{
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "name": defaultName,
        "@id": host,
        itemListElement
    }]
    
    return (
        <Helmet>
            <script type="application/ld+json">
                {JSON.stringify(breadCrumbs)}
            </script>
        </Helmet>
    )
}

export default SDBreadcrumb