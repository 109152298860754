import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Cookies from "universal-cookie"
import fingerPrint from "../images/svg/fingerprint.svg"
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"

const CookiesForm = (location) => {
    const {privacy} = useStaticQuery(graphql`
        query {
            privacy: file(relativePath: {eq: "settings/settings-other.md"}) {
                id
                childMarkdownRemark {
                    frontmatter {
                        lblprivacy
                        descprivacypoptitle
                        descprivacypop
                        btnprivacypop
                        btnprivacypopreject
                    }
                }
            }
        }
    `)

    const cookies = new Cookies()
    let currentConsent = (cookies.get('gatsby-gdpr-google-analytics'))
    let consentVisible = false
    if (cookies.get('gatsby-gdpr-google-analytics')!==undefined) {
        consentVisible=true
    }
    let gaExists = false
    if (cookies.get('_ga')!==undefined) {
        gaExists=true
    }
    const [click, setClick] = useState(consentVisible)
    const consentClick = () => setClick(!click)
    const [hascookie, setHasCookie] = useState(consentVisible)
    const setCookie = () => setHasCookie(true)

    const settings = privacy.childMarkdownRemark.frontmatter

    const cookieExpire = new Date()
    cookieExpire.setTime(cookieExpire.getTime() + (28*86400000))

    const setGATrue = () => {
        cookies.set('gatsby-gdpr-google-analytics', true, {path: "/", expires: cookieExpire})
        initializeAndTrack(location)
        consentClick()
        setCookie()
    }
    const setGAFalse = () => {
        cookies.set('gatsby-gdpr-google-analytics', false, {path: "/", expires: cookieExpire})
        consentClick()
        setCookie()
        gaExists &&(window.location.reload())
    }
    
    return (
        <>
            <div id="consent" className="fixed left-0 bottom-0 mt-0 ml-8 mb-8 mr-0 z-50 transition-all duration-75 scale-x-75">
                <button className="focus:outline-none" onClick={consentClick}>
                    <img className="p-1" src={fingerPrint} width="38" height="38" alt="Consent Settings" aria-label="Consent Settings" title="Consent Settings" />
                </button>
            </div>
            <div id="cookieconsent" className={hascookie&&click?
                "bg-primary-pantonea overflow-y-hidden fixed inset-x-0 z-50 -bottom-128 opacity-100 scale-100 border-t-2 border-black transition-all duration-500 ease-in-out"
                :"bg-primary-pantonea overflow-y-hidden fixed inset-x-0 z-50 bottom-0 opacity-100 scale-100 border-t-2 border-black transition-all duration-500 ease-in-out"}>
                <div className="container mx-auto">
                    <div className="w-full p-3">
                        <div className="flex justify-between flex-wrap">
                            <p className="text-white">{settings.descprivacypoptitle}</p>
                            <div className="flex justify-end w-full sm:w-auto">
                                <div>
                                    <button id="cookieaccept" className="rounded bg-primary-actionslider text-white px-3 py-1 mx-2 focus:outline-none hover:bg-white hover:text-primary-actionslider" onClick={() => setGATrue()}>{settings.btnprivacypop}</button>
                                </div>
                                <div>
                                    <button id="cookiereject" className="rounded bg-white text-black px-3 py-1 mx-2 focus:outline-none hover:bg-black hover:text-white"  onClick={setGAFalse}>{settings.btnprivacypopreject}</button>
                                </div>
                            </div>
                        </div>
                        <p className={currentConsent?"text-white text-xs block":"hidden"}>Current Acceptance: {currentConsent}</p>
                        <p className="text-white text-xs">{settings.descprivacypop}</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CookiesForm