import React, { useState, useEffect } from "react"
import LogoIcon from "../../images/svg/LogoIcon"
import { FiMail, FiPhoneOutgoing } from "react-icons/fi"
import { Link, graphql, useStaticQuery } from "gatsby"
import AnchorLink from "react-anchor-link-smooth-scroll"

const Header = ({location}) => {

    const {contact} = useStaticQuery(graphql`
    query {
    contact: allFile(filter: {sourceInstanceName: {eq: "content"} name: {eq: "settings-contact"}}) {
        edges {
        node {
            childMarkdownRemark {
            frontmatter {
                email
                enemailsidenav
                telephone
                entelephonenav
                entelephonesidenav
            }
        }
        }
    }
    }
    }`)
    const contactdata = contact.edges[0].node.childMarkdownRemark.frontmatter

    const [click, setClick] = useState(false)
    const navClick = () => setClick(!click)

    const [scroll, setScroll] = useState(false)
    const changeNav = () => {
        if(window.scrollY >= 100) {
            setScroll(true)
        } else {
            setScroll(false)
        }
    }
    useEffect(() => {
        window.addEventListener("scroll", changeNav, {passive: true})
        return () =>{
            window.removeEventListener("scroll", changeNav, {passive: true})
        }
    }, [])

    let isHomeLocation = false
    isHomeLocation = location.pathname.split('/')[1]===""

    return (
        <>
            <header className={scroll? "headerNavScroll": "headerNav"}>
                <div className="container flex justify-between lg:justify-between items-center mx-auto py-4 px-2">
                    <div id="burger" className="lg:invisible flex">
                        <button className="focus:outline-none" aria-label="Open Menu" onClick={navClick}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="40px" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                            </svg>
                        </button>
                    </div>
                    <div className="flex items-center justify-around w-full">
                    <div className="navMenu w-2/5 mt-4 sm:mt-0 hidden lg:flex justify-around">
                        {isHomeLocation?
                        <>
                            <AnchorLink className="draw-outline draw-outline--tandem px-4 uppercase" href="#top">
                                Home
                            </AnchorLink>
                            <AnchorLink className="draw-outline draw-outline--tandem px-4 uppercase" href="#about">
                                About
                            </AnchorLink>
                            <AnchorLink className="draw-outline draw-outline--tandem px-4 uppercase" href="#portfolio">
                                Portfolio
                            </AnchorLink>
                            {/* <AnchorLink className="draw-outline draw-outline--tandem px-4 uppercase" href="#blog">
                                News
                            </AnchorLink> */}
                        </>
                        :
                        <>
                            <Link className="draw-outline draw-outline--tandem px-4 uppercase" to="/">
                                Home
                            </Link>
                            <Link className="draw-outline draw-outline--tandem px-4 uppercase" to="/about/">
                                About
                            </Link>
                            <Link className="draw-outline draw-outline--tandem px-4 uppercase" to="/portfolio/">
                                Portfolio
                            </Link>
                            {/* <Link className="draw-outline draw-outline--tandem px-4 uppercase" to="/blog/">
                                News
                            </Link> */}
                        </>
                        }
                    </div>
                    <div id="logoIcon" className=" w-1/5 flex items-center text-2xl justify-around">
                        <div className="w-12 mx-3">
                            {isHomeLocation?
                                <AnchorLink href="#top"><LogoIcon /></AnchorLink>
                                :
                                <Link to="/"><LogoIcon /></Link>
                            }
                        </div>
                    </div>
                    <div className="navMenu w-2/5 mt-4 sm:mt-0 hidden lg:flex justify-around">
                        {isHomeLocation? 
                        <>
                            {/* <Link className="draw-outline draw-outline--tandem px-4 uppercase" to="/shop">
                                Shop
                            </Link> */}
                            <AnchorLink className="draw-outline draw-outline--tandem px-4 uppercase" href="#blog">
                                News
                            </AnchorLink>
                            <AnchorLink className="draw-outline draw-outline--tandem px-4 uppercase" href="#testimonials">
                                Testimonials
                            </AnchorLink>
                            <AnchorLink className="draw-outline draw-outline--tandem px-4 uppercase" href="#contact">
                                Contact
                            </AnchorLink>
                        </>
                        :
                        <>
                            <Link className="draw-outline draw-outline--tandem px-4 uppercase" to="/blog/">
                                News
                            </Link>
                            {/* <Link className="draw-outline draw-outline--tandem px-4 uppercase" to="/shop/">
                                Shop
                            </Link> */}
                            <Link className="draw-outline draw-outline--tandem px-4 uppercase" to="/testimonials/">
                                Testimonials
                            </Link>
                            <Link className="draw-outline draw-outline--tandem px-4 uppercase" to="/contact/">
                                Contact
                            </Link>
                        </>
                        }
                    </div>
                    </div>
                    <div className="flex">
                        <a className={contactdata.entelephonenav?  "pr-2 text-primary-actionslider" : "pr-2 invisible"} href={"tel:" + contactdata.telephone} aria-label="Call us now">
                            <FiPhoneOutgoing size={28} />
                        </a>
                    </div>
                </div>
            </header>
            <div id="sideNav" className={click? "sideNavActive" : "sideNavInActive"}>
                <button aria-label="Close Menu" className="top-0 absolute right-0 ml-40 text-6xl pt-6 pr-6 focus:outline-none" onClick={navClick}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="40px" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
                {isHomeLocation?
                <>
                    <ul className="mt-8 leading-loose">
                        <li>
                            <AnchorLink className="uppercase" onClick={navClick} href="#top">Home</AnchorLink>
                        </li>
                        <li>
                            <AnchorLink className="uppercase" onClick={navClick} href="#about">About</AnchorLink>
                        </li>
                        <li>
                            <AnchorLink className="uppercase" onClick={navClick} href="#portfolio">Portfolio</AnchorLink>
                        </li>
                        <li>
                            <AnchorLink className="uppercase"onClick={navClick} href="#blog">News</AnchorLink>
                        </li>
                        {/* <li>
                            <Link className="uppercase" onClick={navClick} to="/shop/">Shop</Link>
                        </li> */}
                        <li>
                            <AnchorLink className="uppercase" onClick={navClick} href="#testimonials">Testimonials</AnchorLink>
                        </li>
                        <li>
                            <AnchorLink className="uppercase" onClick={navClick} href="#contact">Contact</AnchorLink>
                        </li>
                    </ul>
                </>
                :
                <>
                    <ul className="mt-8 leading-loose">
                        <li>
                            <Link className="uppercase" onClick={navClick} to="/">Home</Link>
                        </li>
                        <li>
                            <Link className="uppercase" onClick={navClick} to="/about/">About</Link>
                        </li>
                        <li>
                            <Link className="uppercase" onClick={navClick} to="/portfolio/">Portfolio</Link>
                        </li>
                        <li>
                            <Link  className="uppercase"onClick={navClick} to="/blog/">News</Link>
                        </li>
                        {/* <li>
                            <Link className="uppercase" onClick={navClick} to="/shop/">Shop</Link>
                        </li> */}
                        <li>
                            <Link className="uppercase" onClick={navClick} to="/testimonials/">Testimonials</Link>
                        </li>
                        <li>
                            <Link className="uppercase" onClick={navClick} to="/contact/">Contact</Link>
                        </li>
                    </ul>
                </>
                }
                <div className="flex justify-center pt-10">
                    <a className={contactdata.entelephonesidenav? "my-auto px-2" : "hidden"} href={"tel:" + contactdata.telephone} aria-label="Call us now">
                        <FiPhoneOutgoing size={24} />
                    </a>
                    <a className={contactdata.enemailsidenav? "my-auto px-2" : "hidden"} href={"mailto:" + contactdata.email} aria-label="Email us now">
                        <FiMail size={24} />
                    </a>
                </div>
            </div>
        </>
    )
}

export default Header