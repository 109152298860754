import React from "react"

const sizes = {
  sm: `py-2 px-4`,
  default: `py-3 px-8`,
  lg: `py-4 px-12`,
  xl: `py-5 px-16 text-lg`
};

const Button = ({ children, className = '', size, buttonType }) => {
  return (
    <button
      type={buttonType || "button"}
      className={`
        ${sizes[size] || sizes.default}
        ${className}
        bg-primary-action
        hover:bg-primary-darker
        rounded-xl
        text-white
        focus:outline-none
        transition duration-150 ease-in-out
        transform hover:scale-105
    `}
    >
      {children}
    </button>
  );
};

export default Button