import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { IoMdRocket } from "react-icons/io"
import { ImLocation, ImLink, ImTwitch, ImFacebook, ImTwitter, ImPinterest, ImInstagram, ImHome, ImParagraphLeft, ImParagraphRight } from "react-icons/im"
import { FiPhoneOutgoing, FiMail } from "react-icons/fi"

const Footer = () => {
    const {contact, social, other} = useStaticQuery(graphql`
    query {
    contact: allFile(filter: {sourceInstanceName: {eq: "content"} name: {eq: "settings-contact"}}) {
        edges {
        node {
            childMarkdownRemark {
            frontmatter {
                lblcontact
                enaddress
                address1
                address2
                address3
                address4
                address5
                enemail
                email
                entelephone
                telephone
            }
        }
        }
    }
    }
    social: allFile(filter: {sourceInstanceName: {eq: "content"} name: {eq: "settings-social"}}) {
        edges {
        node {
            childMarkdownRemark {
            frontmatter {
                lblsocial
                enfacebook
                lblfacebook
                facebook
                entwitter
                lbltwitter
                twitter
                enpinterest
                lblpinterest
                pinterest
                eninstagram
                lblinstagram
                instagram
                enhouzz
                lblhouzz
                houzz
            }
        }
        }
    }
    }
    other: allFile(filter: {sourceInstanceName: {eq: "content"} name: {eq: "settings-other"}}) {
        edges {
        node {
            childMarkdownRemark {
            frontmatter {
                lblother
                enterms
                lblterms
                enprivacy
                lblprivacy
                encovid
                lblcovid
            }
        }
        }
    }
    }
    }`)
    const contactdata = contact.edges[0].node.childMarkdownRemark.frontmatter
    const socialdata = social.edges[0].node.childMarkdownRemark.frontmatter
    const otherdata = other.edges[0].node.childMarkdownRemark.frontmatter

    return (
        <>
            <div className="w-full bg-primary-pantonea mt-48">
                <footer className="container flex flex-col sm:flex-row mx-auto py-4 px-8 pb-12 bg-primary-pantonea text-gray-100">
                    <div className="flex-1 pt-5">
                        <span className="inline-flex items-baseline">
                            <ImLink />
                            <h2 className="text-2xl pl-2">
                                {otherdata.lblother}
                            </h2>
                        </span>
                        <ul className="mt-4 leading-loose">
                            <li className={otherdata.enterms? "pb-1" : "hidden"}>
                                <span className="inline-flex items-baseline">
                                    <ImParagraphLeft />
                                    <Link to="/terms/" className="pl-2">
                                        {otherdata.lblterms}
                                    </Link>
                                </span>
                            </li>
                            <li className={otherdata.enprivacy? "py-1" : "hidden"}>
                                <span className="inline-flex items-baseline">
                                    <ImParagraphRight />
                                    <Link to="/privacy/" className="pl-2">
                                        {otherdata.lblprivacy}
                                    </Link>
                                </span>
                            </li>
                            <li className={otherdata.encovid? "py-1" : "hidden"}>
                                <span className="inline-flex items-baseline">
                                    <ImParagraphLeft />
                                    <Link to="/covid/" className="pl-2">
                                        {otherdata.lblcovid}
                                    </Link>
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div className="flex-1 pt-5">
                        <span className="inline-flex items-baseline"><ImTwitch />
                            <h2 className="text-2xl pl-2">
                                {socialdata.lblsocial}
                            </h2>
                        </span>
                        <ul className="mt-4 leading-loose">
                            <li className={socialdata.enfacebook? "pb-1" : "hidden"}>
                                <span className="inline-flex items-baseline">
                                    <ImFacebook />
                                    <a href={socialdata.facebook} target="_blank" rel="noreferrer" className="pl-2">
                                        {socialdata.lblfacebook}
                                    </a>
                                </span>
                            </li>
                            <li className={socialdata.entwitter? "py-1" : "hidden"}>
                                <span className="inline-flex items-baseline">
                                    <ImTwitter />
                                    <a href={socialdata.twitter} target="_blank" rel="noreferrer" className="pl-2">
                                        {socialdata.lbltwitter}
                                    </a>
                                </span>
                            </li>
                            <li className={socialdata.enpinterest? "py-1" : "hidden"}>
                                <span className="inline-flex items-baseline">
                                    <ImPinterest />
                                    <a href={socialdata.pinterest} target="_blank" rel="noreferrer" className="pl-2">
                                        {socialdata.lblpinterest}
                                    </a>
                                </span>
                            </li>
                            <li className={socialdata.eninstagram? "py-1" : "hidden"}>
                                <span className="inline-flex items-baseline">
                                    <ImInstagram />
                                    <a href={socialdata.instagram} target="_blank" rel="noreferrer" className="pl-2">
                                        {socialdata.lblinstagram}
                                    </a>
                                </span>
                            </li>
                            <li className={socialdata.enhouzz? "py-1" : "hidden"}>
                                <span className="inline-flex items-baseline">
                                    <ImHome />
                                    <a href={socialdata.houzz} target="_blank" rel="noreferrer" className="pl-2">
                                        {socialdata.lblhouzz}
                                    </a>
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div className="flex-1 pt-5">
                        <span className="inline-flex items-baseline">
                            <ImLocation />
                            <h2 className="text-2xl pl-2">
                                { contactdata.lblcontact }
                            </h2>
                        </span>
                        <p className={contactdata.enaddress? "mt-5 text-white" : "hidden"}>
                            { contactdata.address1 }
                            <br />
                            { contactdata.address2 }
                            <br />
                            { contactdata.address3 }
                            <br />
                            { contactdata.address4 }
                            <br />
                            { contactdata.address5 }
                        </p>
                        <ul className="mt-4 leading-loose">
                            <li className={contactdata.enemail? "pb-1" : "hidden"}>
                                <span className="inline-flex items-baseline">
                                    <FiMail />
                                    <a href={"mailto:" + contactdata.email} className="pl-2">{contactdata.email}</a>
                                </span>
                            </li>
                            <li className={contactdata.entelephone? "py-1" : "hidden"}>
                                <span className="inline-flex items-baseline">
                                    <FiPhoneOutgoing />
                                    <a href={"tel:" + contactdata.telephone} className="pl-2">{ contactdata.telephone }</a>
                                </span>
                            </li>
                        </ul>
                    </div>
                </footer>
                <div className="container flex flex-col justify-between sm:flex-row mx-auto py-4 px-8 pb-12 bg-primary-pantonea">
                    <div>
                        <p className="text-gray-100 text-sm">&copy; {(new Date().getFullYear())} Thistlegrey Interiors</p>
                    </div>
                    <div>
                        <p className="text-gray-100 text-sm"><a href="https://www.rokitmedia.co.uk" aria-label="Rokit Media Web Design">Website Design by Rokit Media<IoMdRocket className="inline" size={15} /></a></p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer
