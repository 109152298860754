import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

// JSON-LD

const SDLocalBusiness = () => {

    // feature: article
    // https://schema.org/ProfessionalService
    // https://developers.google.com/search/docs/data-types/local-business

    const { site } = useStaticQuery(
        graphql`
        query {
            site: file(relativePath: {eq: "settings/settings-sitemeta.md"}) {
                id
                childMarkdownRemark {
                  frontmatter {
                    defaultUrl: siteurl
                    defaultName: titlecompany
                    defaultTitle: title
                    defaultDescription: description
                    defaultAuthor: author
                    streetAddress
                    addressLocality
                    addressRegion
                    postalCode
                    addressCountry
                    telephoneNumber
                    contactURL
                    longitude
                    latitude
                    defaultImage: defimage {
                      publicURL
                    }
                    defaultLogo: logo {
                      publicURL
                    }
                  }
                }
              }
        }
      `)
    
        const {
            defaultUrl,
            defaultName,
            defaultDescription,
            defaultImage,
            defaultLogo,
            streetAddress,
            addressLocality,
            addressRegion,
            postalCode,
            addressCountry,
            telephoneNumber,
            contactURL,
            longitude,
            latitude,
        } = site.childMarkdownRemark.frontmatter

    const writeData = () => {
        const scriptData = JSON.stringify(
            {
                "@context": "https://schema.org",
                "@type": "ProfessionalService",
                "description": defaultDescription,
                "additionalType": "https://en.wikipedia.org/wiki/Interior_design",
                "@id": defaultUrl,
                "name": defaultName,
                "url": defaultUrl,
                "telephone": telephoneNumber,
                "image": defaultUrl+defaultImage.publicURL,
                "logo": defaultUrl+defaultLogo.publicURL,
                "address": {
                    "@type": "PostalAddress",
                    "name": defaultName+" Address",
                    "streetAddress": streetAddress,
                    "addressLocality": addressLocality,
                    "addressRegion": addressRegion,
                    "postalCode": postalCode,
                    "addressCountry": addressCountry,
                    "@id": defaultUrl+contactURL
                },
                "geo": {
                    "@type": "GeoCoordinates",
                    "longitude": longitude,
                    "latitude": latitude,
                    "name": defaultName+" Geo Coordinates",
                },
                "openingHoursSpecification": [
                    {
                        "@type": "OpeningHoursSpecification",
                        "dayOfWeek": [
                            "Monday",
                            "Tuesday",
                            "Wednesday",
                            "Thursday",
                            "Friday"
                        ],
                        "opens": "09:00",
                        "closes": "05:30",
                        "name": "Opening Hours Mon-Fri",
                    }
                ],
            }
    )
        return(
            scriptData
        )}
    return(
        <>
        <Helmet>
            <script type="application/ld+json">
                {writeData()}
            </script>
        </Helmet>
        </>
    )
}

export default SDLocalBusiness