import React from "react"
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';
import AnchorLink from "react-anchor-link-smooth-scroll"
import Slider from "react-slick"
import LogoHero from "../images/logo/tg-logo.svg"
import Button from "./Button"
import { BsBoxArrowInDown } from "react-icons/bs"

const HeroSlider = () => {
    const {herosettings} = useStaticQuery(graphql`
        query {

            herosettings: file(relativePath: {eq: "settings/settings-heroslider.md"}) {
                id
                childMarkdownRemark {
                  frontmatter {
                    actionbtntxt
                    heroimage1alt
                    heroimage2alt
                    heroimage3alt
                    heroimage4alt
                    heroimage1 {
                        childImageSharp {
                            fluid(maxWidth: 2400, srcSetBreakpoints: [640, 768, 1024, 1280, 1536], quality: 65) {
                                ...GatsbyImageSharpFluid_withWebp 
                            }
                        }
                      publicURL
                      extension
                    }
                    heroimage2 {
                        childImageSharp {
                            fluid(maxWidth: 2400, srcSetBreakpoints: [640, 768, 1024, 1280, 1536], quality: 65) {
                                ...GatsbyImageSharpFluid_withWebp 
                            }
                        }
                      publicURL
                      extension
                    }
                    heroimage3 {
                        childImageSharp {
                            fluid(maxWidth: 2400, srcSetBreakpoints: [640, 768, 1024, 1280, 1536], quality: 65) {
                                ...GatsbyImageSharpFluid_withWebp 
                            }
                        }
                      publicURL
                      extension
                    }
                    heroimage4 {
                        childImageSharp {
                            fluid(maxWidth: 2400, srcSetBreakpoints: [640, 768, 1024, 1280, 1536], quality: 65) {
                                ...GatsbyImageSharpFluid_withWebp 
                            }
                        }
                      publicURL
                      extension
                    }
                  }
                }
              }
        }
    `)
    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        arrows: true,
        lazyLoad: true,
    }
    const images = herosettings.childMarkdownRemark.frontmatter
    return (
        <>
            <section className="-mt-20 overflow-hidden relative">
                <div className="px-2 top-0 right-0 left-0 z-10 absolute flex items-center">
                    <div className="container mx-auto items-center flex flex-col pt-32 sm:pt-4 md:pt-32">
                        <div className="flex flex-col place-items-center justify-around lg:w-1/2 pl-4 pr-4 pt-8">
                            <div className="flex-1">
                                <AnchorLink href="#about" alt="Continue reading about us">
                                    <Button size="sm" className="bg-primary-actionslider mb-4">
                                        <span className="text-xl font-thin tracking-widest">
                                            {images.actionbtntxt}
                                        </span>
                                    </Button>
                                    <BsBoxArrowInDown size="48" className="animate-bounce text-primary-actionslider m-auto"/>
                                </AnchorLink>
                            </div>
                        </div>
                        <div className="flex justify-around w-full order-first">
                            <div className="bg-primary-pantonea bg-opacity-100 p-4 inline-block">
                                <img width="192" height="278" src={LogoHero} alt="Thistlegrey Logo" className="top-0 right-0 w-32 md:w-40 lg:w-48" />
                            </div>
                        </div>
                    </div>
                </div>
                <Slider {...settings}>
                    <div>
                        <Img className="w-screen h-screen" fluid={images.heroimage1.childImageSharp.fluid} alt={images.heroimage1alt} />
                    </div>
                    {images.heroimage2 && 
                        <div>
                            <Img className="w-screen h-screen" fluid={images.heroimage2.childImageSharp.fluid} alt={images.heroimage2alt} />
                        </div>
                    }
                    {images.heroimage3 && 
                        <div>
                            <Img className="w-screen h-screen" fluid={images.heroimage3.childImageSharp.fluid} alt={images.heroimage3alt} />
                        </div>
                    }
                    {images.heroimage4 && 
                        <div>
                            <Img className="w-screen h-screen" fluid={images.heroimage4.childImageSharp.fluid} alt={images.heroimage4alt} />
                        </div>
                    }
                </Slider>
            </section>
        </>
    )
}

export default HeroSlider